<template>
  <v-card-text>
    <v-row>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="category_model.code"
            label="Code"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="category_model.name"
            sm="12"
            cols="6"
            label="Name"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
          v-if="update === '1'"
      >
        <v-checkbox
            v-model="category_model.active"
            label="Active"
            :value="1"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: "CategoryFormComponent",
  data: () => ({
    RequiredRules: [
      v => !!v || 'Field cannot empty',
    ],
  }),
  props: {
    category_model: Object,
    update: String,
  }
}
</script>

<style scoped>

</style>
