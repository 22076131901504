<template>
  <v-container>
    <row-header action="back" :msg="msgRowHeader"></row-header>

    <v-row dense>
      <v-col>
        <v-form
            ref="form"
            v-model="valid"
        >
          <v-card>
            <category-form-component :category_model="category_model" update="1" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  :disabled="!valid"
                  color="success"
                  @click="saveData"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <v-overlay
        :absolute="absolute"
        :value="overlay"
    >
      <v-card color="white">
        <v-card-text>
          <overlay-img></overlay-img>
        </v-card-text>
      </v-card>
    </v-overlay>

  </v-container>
</template>

<script>
import RowHeader from "../../../components/content/RowHeader";
import OverlayImg from "../../../components/content/OverlayImg";
import CategoryFormComponent from '../../../components/page/categories/CategoryFormComponent';
import { CategoryData } from '../../../store/services/CategoryModel';
import { PutRequest, GetRequest } from "../../../store/services/users";

export default {
  components: {
    RowHeader,
    OverlayImg,
    CategoryFormComponent,
  },
  data: () => ({
    msgRowHeader: {newUser: 'Back', newMsg: 'Update Merchant'},
    valid: true,
    absolute: false,
    overlay: false,
    category_model: new CategoryData(),
  }),
  async mounted() {
    console.log('category update view');
    if (this.$store.getters.isLoggedIn) {
      await this.getCategoryData();
    }
  },
  methods: {
    async getCategoryData() {
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try {
        const response = await GetRequest('categories/' + this.$route.params._id);
        delete response.data._id;
        delete response.data.created_at;
        delete response.data.created_by;
        delete response.data.updated_at;
        delete response.data.updated_by;
        delete response.data.__v
        this.category_model = response.data;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Get user data failed ' + e.message);
      }
    },
    async saveData() {
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try {
        console.log(this.category_model);
        const response = await PutRequest('categories/' + this.$route.params._id, this.category_model);
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        if (response.data && response.message === 'updated') {
          alert('success to updated data');
          await this.$router.push({name: 'CategoryList'});
        } else {
          alert('failed to updated data');
        }
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Get data failed ' + e.message);
      }
    },
  }
}
</script>

<style scoped>

</style>
